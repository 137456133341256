import React from 'react';
import Image from 'next/image';

import MaxWidthContainer from 'components/MaxWidthContainer';

const MobileTabBar = (props) => {
  const { ...restProps } = props;

  return (
    <>
      <footer className="mobiletabbar-frame fixed bottom-0 left-0 z-20 flex w-full border-t border-gray-100 bg-white lg:hidden">
        <MaxWidthContainer className="h-full flex-wrap">
          <div className="mobiletabbar-grid grid h-full w-full grid-cols-5 gap-4 px-2">
            <div className="mobiletabbar-grid-item flex items-center justify-center">
              <div
                onClick={() => {
                  window.location = '/';
                }}
              >
                <img
                  src={
                    restProps.props.highlighted == 'home'
                      ? '/svgs/mobileTab-home-highlighted.svg'
                      : '/svgs/mobileTab-home.svg'
                  }
                ></img>
                <span className="text-xs text-sorra-gray-800">搜尋</span>
              </div>
            </div>
            <div className="mobiletabbar-grid-item flex items-center justify-center">
              <div
                onClick={() => {
                  window.location = '/ranking';
                }}
              >
                <img
                  src={
                    restProps.props.highlighted == 'leaderboard'
                      ? '/svgs/mobileTab-leaderboard-highlighted.svg'
                      : '/svgs/mobileTab-leaderboard.svg'
                  }
                ></img>
                <span className="text-xs text-sorra-gray-800">美妝排行</span>
              </div>
            </div>
            <div className="mobiletabbar-grid-item flex items-center justify-center">
              <div
                onClick={() => {
                  window.location = '/campaign';
                }}
              >
                <img
                  src={
                    restProps.props.highlighted == 'campaign'
                      ? '/svgs/mobileTab-sparkles-highlighted.svg'
                      : '/svgs/mobileTab-sparkles.svg'
                  }
                ></img>
                <span className="text-xs text-sorra-gray-800">活動</span>
              </div>
            </div>
            <div className="mobiletabbar-grid-item flex items-center justify-center">
              <div
                onClick={() => {
                  window.location = '/rewards';
                }}
              >
                <img
                  src={
                    restProps.props.highlighted == 'score'
                      ? '/svgs/mobileTab-score-highlighted.svg'
                      : '/svgs/mobileTab-score.svg'
                  }
                ></img>
                <span className="text-xs text-sorra-gray-800">獎賞優惠</span>
              </div>
            </div>
            <div className="mobiletabbar-grid-item flex items-center justify-center">
              <div
                onClick={() => {
                  window.location = '/profile';
                }}
              >
                <img
                  src={
                    restProps.props.highlighted == 'account'
                      ? '/svgs/mobileTab-account-highlighted.svg'
                      : '/svgs/mobileTab-account.svg'
                  }
                ></img>
                <span className="text-xs text-sorra-gray-800">帳戶</span>
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </footer>
    </>
  );
};

export default MobileTabBar;
